import * as PIXI from 'pixi.js';
import {Spine} from 'pixi-spine';

import React, { useState, useEffect } from "react";
import { PixiComponent, Stage, Container, Sprite, useTick } from "@inlet/react-pixi";
import { settings, SCALE_MODES } from "pixi.js";

import Loader from 'react-loader-spinner';

import SpineComponent from "./SpineComponent";

window.PIXI = PIXI;

/*
const app = new PIXI.Application({
    width:400, 
    height:400, 
    view: document.getElementById('dinoCanvas'),
antialias: true,
        backgroundColor: 0x282c34,
clearBeforeRender: false
    });

// document.body.appendChild(app.view);

//app.stop();

app.loader
.add('spineCharacter', 'spine-data-1/Trex2.json')
.load(function (loader, resources) {
const animation = new Spine(resources.spineCharacter.spineData);

console.log(animation);

// set the position
animation.x = app.screen.width / 2 + 20;
animation.y = app.screen.height - 50;

animation.scale.set(0.2, 0.2); // negative to flip X or Y

// add the animation to the scene and render...
app.stage.addChild(animation);

let animationName = "idle";
  
if (animation.state.hasAnimation(animationName)) {
console.log("has animation!");
// idle forever, little boy!
animation.state.setAnimation(0, animationName, true);
// dont idle too fast
animation.state.timeScale = 1;
}

});

app.loader.onProgress.add(() => {}); // called once per loaded/errored file
app.loader.onError.add(() => {}); // called once per errored file
app.loader.onLoad.add(() => {}); // called once per loaded file
app.loader.onComplete.add(() => {
  app.start();
  

  document.getElementById('dinoCanvas').style.display = 'block'; // display the canvas now that it has loaded
}); // called once when the queued resources all load.
*/

// from https://codesandbox.io/s/next-js-react-pixi-jcmt1?file=/components/PixiApp.js:0-160
settings.SCALE_MODE = SCALE_MODES.NEAREST;

const RotatingBunny = () => {
  const [rotation, setRotation] = useState(0);

  useTick((delta) => delta && setRotation(rotation + 0.1 * delta));

  return (
    <Sprite
      image="https://s3-us-west-2.amazonaws.com/s.cdpn.io/693612/IaUrttj.png"
      anchor={0.5}
      scale={4}
      rotation={rotation}
    />
  );
};

// Overall design of this and SpineComponent from
// https://gist.github.com/ascorbic/f01009247f859f0d76b8b92d34ae87bd
// (did my best to convert from TypeScript)
export default function MyPixi() {

  const [ spineData, setSpineData ] = useState();
 // const { animationState, setAnimationState } = useState(PIXI.spine.core.AnimationState | undefined);

  useEffect(() => {
      const initSpine = async () => {
          await import("pixi-spine");
          const loader = new PIXI.Loader();

          loader
              .add("spineboy", "https://storage.googleapis.com/prehistoria-bucket-us/Trex2.json") //"spine-data-1/Trex2.json")
              .load((loader, res) => {
                setSpineData(res?.spineboy?.spineData);
              });
      };
      initSpine();
  }, []);


  // https://reactpixi.org/stage
  const width = 500
  const height = 500
  const stageProps = {
    height,
    width,
    options: {
      backgroundColor: 0x282c34,
      backgroundAlpha: 1,
      antialias: true,
    }
  }

  return (
    <div className="App">
      <Stage  {...stageProps}>
        <Container position={[0, 0]}>
        {spineData && (          
          <SpineComponent 
                        x={280}
                        y={450}
                        spineData={spineData}
                        scale={0.25}
            />
          )}            
        </Container>
      </Stage>
      {!spineData && (
        <Loader style={{ position: "relative", top: "-200", left: "0", yindex: "1001"}} type="ThreeDots" color="#eeeeee" height="100" width="70" />
      )}
     </div>      
    );
}
  
