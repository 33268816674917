//import logo from './logo.svg';
import './App.css';
import MyPixi from './MyPixi';
import Rand from './Rand';
import {   BrowserRouter as Router,  Switch, Route } from "react-router-dom";


function App() {
  let path = window.location.pathname;
  let n = path.lastIndexOf('/');
  let tokenId = path.substring(n + 1); // grab the text after the last /
  if (!tokenId || tokenId === "") {
    tokenId = "TokenID Not supplied";
  }
  return (
    <Router>
      <Switch>
        <Route path="/rand">
          <Rand/>
        </Route>
        <Route path="/">
          <MyPixi/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
