export default function Rand() {
  let myPix = new Array("https://i.imgur.com/WIo04oj.gif",
                        "https://i.imgur.com/OEOby16.gif",
                        "https://i.imgur.com/VM3U114.gif",
                        "https://i.imgur.com/QyVVPyF.gif",
                        "https://i.imgur.com/dVeO6pA.gif"
  );
  let randomNum = Math.floor(Math.random() * myPix.length);
  console.log(myPix[randomNum] + `?${new Date().getTime()}`);

//  const variableAttribute = { [src]: `${myPix[randomNum]}?${new Date().getTime()}` } ;

  const src = {}
  src['src'] = `${myPix[randomNum]}?${new Date().getTime()}`;

  return (
    <div className="App">
      <img alt=""  { ...src } />
    </div>
    )
}
  
