/*import * as PIXI from 'pixi.js';
import {Spine} from 'pixi-spine';*/

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

	
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
	
/*
const app = new PIXI.Application({
				width:400, 
				height:400, 
				view: document.getElementById('dinoCanvas'),
        antialias: true,
//        backgroundColor: 0x282c34,
        clearBeforeRender: false
				});

// document.body.appendChild(app.view);

//app.stop();

app.loader
    .add('spineCharacter', 'spine-data-1/Trex2.json')
    .load(function (loader, resources) {
        const animation = new Spine(resources.spineCharacter.spineData);

		console.log(animation);

	  // set the position
		animation.x = app.screen.width / 2 + 20;
		animation.y = app.screen.height - 50;
		
		animation.scale.set(0.2, 0.2); // negative to flip X or Y

        // add the animation to the scene and render...
        app.stage.addChild(animation);
        
        if (animation.state.hasAnimation('idle')) {
			console.log("has animation!");
            // idle forever, little boy!
            animation.state.setAnimation(0, 'idle', true);
            // dont idle too fast
            animation.state.timeScale = 0.25;
        }
        
      app.start();
      
    });
*/